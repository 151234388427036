import clsx from "clsx";
import { hyphenateSync } from 'hyphen/ru';
import moment from "moment";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Expander, Icon } from "ui-kit-euroopt";
import { request } from "../../helpers/request";
import { Adaptive } from "../adaptive";
import { Arrow } from "../arrow";
import cls from "../Body/index.module.scss";
import { Container } from "../container";
import { ContainerFluid } from "../container-fluid";
import { openFieldModal } from "../modal/filter";
import classNames from "classnames";

interface IBlock {
	name: string | JSX.Element;
	children?: string | JSX.Element | JSX.Element[];
	mix?: string;
	id?: string;
}

const Block: FC<IBlock> = ({ name, children, mix, id }) => {
	return (
		<section id={id} className={clsx(cls.block, mix)}>
			<h2 className={cls.block__name}>{name}</h2>
			<div className={cls.block__body}>{children}</div>
		</section>
	);
};

interface IDrawsAndWinnersCard {
	idx: number;
	date: string;
	list: IList[];
	listEd?: IList[];
	superDraws?: boolean;
	mainDraw?: boolean;
	winner?: boolean;
}

const PrizesInDrawsAndWinnersCard = () => (
	<>
		{" "}
		<span>15 денежных призов по 500 р.</span>
		<span>5 денежных призов по 1 000 р.</span>
	</>
);

const DrawsAndWinnersCard: FC<IDrawsAndWinnersCard> = ({
	idx,
	date,
	superDraws,
	mainDraw,
	winner,
	list,
	listEd,
}) => {
	return (
		<div
			className={clsx(
				cls.card,
				superDraws && cls.card__superDraws,
				winner && cls.card__winner
			)}
			onClick={() => winner && openFieldModal(list, idx, listEd)}
		>
			<div className={cls.card__title}>
				<span className={cls.card__title__left}>
				  {superDraws ? `Суперприз ${idx === 4 ? 1 : 2}` : mainDraw ? 'Главный розыгрыш' : `${idx} неделя`}
				</span>
				{winner ? (
					<span className={cls.card__title__rigth__blue}>
            			Результаты <Icon name="chevron-12" />
          			</span>
				) : (
					<span className={cls.card__title__rigth}>{date}</span>
				)}
			</div>
			<div className={cls.card__body}>
				{!superDraws && !mainDraw && (
					<PrizesInDrawsAndWinnersCard />
				)}
				{superDraws && (
					<span className={cls.card__body__super}>1 денежный приз 10 000 р.</span>
				)}
				{mainDraw && (
					<span className={cls.card__body__super}>1 денежный приз 50 000 р.</span>
				)}
			</div>
		</div>
	);
};

interface IDrawsAndWinners {
	list: IList[];
	listEd: IList[];
}

const DrawsAndWinners: FC<IDrawsAndWinners> = ({ list, listEd }) => {
	const [arrowsIsVisible, setArrowsIsVisible] = useState<{
		left: boolean;
		right: boolean;
	}>({left: false, right: false});
	const wrapperSingleRef = useRef<null | HTMLDivElement>(null);
	const [isScrolling, setIsScrolling] = useState(false);
	let scrollTimeout: NodeJS.Timeout;

	const checkArrowIsVisible = (double = false): void => {
		const element = wrapperSingleRef.current;
		if (element) {
			if (element.scrollWidth) {
				setArrowsIsVisible({
					left: !!element.scrollLeft,
					right:
						element.scrollWidth - element.offsetWidth !== element.scrollLeft,
				});
				if (double) {
					setTimeout(() => {
						checkArrowIsVisible();
					}, 300);
				}
			}
		}
	};

	const scrollTo = (direction: "left" | "right"): void => {
		const element = wrapperSingleRef.current;

		if (element) {
			const {scrollLeft, children} = element;
			const {width} = children[0].getBoundingClientRect();
			const widthWithPadding = (width + 16) * 3;
			const position =
				direction === "right"
					? scrollLeft + widthWithPadding
					: scrollLeft - widthWithPadding;
			element.scroll({left: position, behavior: "smooth"});
			setTimeout(() => checkArrowIsVisible(true), 300);
		}
	};

	const handleScroll = () => {
		if (!isScrolling) {
			setIsScrolling(true);
		}

		clearTimeout(scrollTimeout);

		scrollTimeout = setTimeout(() => {
			setIsScrolling(false);

			checkArrowIsVisible();
		}, 500);
	};

	useEffect(() => {
		setTimeout(checkArrowIsVisible, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wrapperSingleRef.current]);

	const arr = [
		{
			DrawDate: "2025-01-23T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-01-30T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-02-06T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-02-13T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-02-13T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-02-20T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-02-27T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-03-06T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-03-13T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-03-13T16:00:00",
			Winners: [],
		},
		{
			DrawDate: "2025-03-13T16:00:00",
			Winners: [],
		},
	];

	const Logos: FC = () => {
		const actualList = list.length ? [...list, arr[arr.length-1]] : arr;
		return (
			<Fragment>
				{actualList.map((el, idx) => (
					<DrawsAndWinnersCard
						date={moment(el.DrawDate).format("DD.MM")}
						superDraws={idx === actualList.length - 2  || idx === 4}
						mainDraw={idx === actualList.length - 1}
						winner={!!el.Winners.length}
						idx={idx < 4 ? idx + 1 : idx}
						key={idx}
						list={actualList}
						listEd={listEd}
					/>
				))}
			</Fragment>
		);
	};

	return (
		<Fragment>
			<Adaptive.Desktop>
				<Container mix={cls.container}>
					{arrowsIsVisible.left ? (
						<>
							<div className={classNames(cls.gradient, cls.gradient_left)} />
							<Arrow
								onClick={() => scrollTo("left")}
								size="medium"
								direction="left"
								mix={cls.arrow}
							/>
						</>
					) : null}

					<div ref={wrapperSingleRef} onScroll={handleScroll} className={cls.wrapper_single}>
						<Logos />
					</div>

					{arrowsIsVisible.right ? (
						<>
							<div className={classNames(cls.gradient, cls.gradient_right)} />
							<Arrow
								size="medium"
								onClick={() => scrollTo("right")}
								direction="right"
								mix={clsx(cls.arrow, cls.arrow_right)}
							/>
						</>
					) : null}
				</Container>
			</Adaptive.Desktop>
			<Adaptive.Mobile>
				<ContainerFluid mix={cls.wrapper_single}>
					<Logos />
				</ContainerFluid>
			</Adaptive.Mobile>
			<div className={cls.text}>Полное наименование призов смотрите в <a target="_blank" href="/rules.pdf">правилах</a> проведения рекламной игры</div>
		</Fragment>
	);
};

const faq = [
	{
		title: "Какие даты проведения рекламной игры?",
		desc: `Для участия в рекламной игре приобретайте товары-везунчики в период с 15 января по 11 марта 2025 года. Обратите внимание, что в последний день игры заказ с игровыми товарами должен быть получен. По заказам, оформленным в период действия игры, но полученным после ее завершения, коды не начисляются.`,
	},
	{
		title: 'В каких магазинах необходимо совершать покупки, чтобы стать участником розыгрышей?',
		desc: 'Совершайте покупки на сайтах <a href="https://edostavka.by" target="_blank">edostavka.by</a> и <a href="https://emall.by" target="_blank">emall.by</a>, а также в мобильных приложениях Едоставка и Емолл.'
	},
	{
		title: "Как стать участником рекламной игры?",
		desc: `Для участия необходимо:
    </br>
    </br>
    <ul>
        <li>достоверно заполнить персональные данные (фамилия, имя, отчество, телефон, email) и дать своё согласие на участие в рекламной игре в личном кабинете на сайте <a href="https://edostavka.by/advertising-game" target="_blank">edostavka.by</a> или <a href="https://emall.by/advertising-game" target="_blank">emall.by</a>;</li>
        <li>совершить покупку товаров-везунчиков на сумму от 2 руб. в период с 10:00:00 15 января по 23:59:59 11 марта 2025 года.</li>
    </ul>
    `,
	},
	{
		title: "Как начисляются коды?",
		desc: `Игровые коды начисляются:
    </br>
    </br>
    <ul>
        <li>за каждые 2 руб. стоимости товаров-везунчиков – 1 игровой код;</li>
        <li>на следующий рабочий день после получения заказа (обратите внимание – не после оформления заказа, а после получения).</li>
    </ul>
    </br>
    Проверить наличие игровых кодов можно в личном кабинете на сайтах <a href="https://edostavka.by/advertising-game" target="_blank">edostavka.by</a> и <a href="https://emall.by/advertising-game" target="_blank">emall.by</a> в разделе «Игровые коды». Номера игровых кодов отображаются не позднее чем за сутки до даты проведения розыгрыша.`,
	},
	{
		title: "Какие товары необходимо купить для участия в рекламной игре?",
		desc: `Для участия в рекламной игре необходимо купить любой товар-везунчик на сумму от 2 руб. Со всеми товарами, участвующими в рекламной игре, можно ознакомиться на сайтах <a href="https://edostavka.by/actions/lucky-goods" target="_blank">edostavka.by</a> и <a href="https://emall.by/actions/lucky-goods" target="_blank">emall.by.</a>`,
	},
	{
		title: "В розыгрыше каких призов участвуют мои игровые коды?",
		desc: `В рамках рекламной игры Вы можете стать победителем 4 раза: 
    </br>
    </br>
    <ul>
        <li>1 раз в розыгрыше денежного приза на сумму 500 р.;</li>
        <li>1 раз в розыгрыше денежного приза на сумму 1 000 р.;</li>
        <li>1 раз в розыгрыше денежного приза на сумму 10 000 р.;</li>
        <li>1 раз в розыгрыше денежного приза на сумму 50 000 р.</li>
    </ul>`,
	},
	{
		title: "Что делать, если мне не приходит код?",
		desc: `Проверьте:
    </br>
    </br>
    <ul>
  <li>подтверждено ли согласие на участие в рекламной игре;</li>
  <li>правильно ли указаны персональные данные в личном кабинете;</li>
  <li>есть ли в заказе товары-везунчики на сумму от 2 руб.;</li>
  <li>доставлен (получен) ли заказ;</li>
  <li>вышел ли срок начисления кодов, указанный в пункте «Как начисляются коды?».</li>
  </ul>
  </br>Если все пункты соблюдены, но игровой код всё равно не начислен, обращайтесь на инфолинию по телефону: <a href="tel:+375297710991" target="_blank">+375 29 771 09 91</a> (МТС, A1, life:)), 8:30-20:30, пн-вс`,
	},
	{
		title: "Когда и где проходят розыгрыши?",
		desc: `Розыгрыши будут проходить по адресу г. Минск, пер. Козлова, 25, пом. 22 (7 этаж).</br>
    С графиком проведения розыгрышей можно ознакомиться <span id="scroll">здесь</span>.`,
	},
	{
		title: "Как я узнаю, что стал победителем?",
		desc: "В течение 2 рабочих дней после завершения розыгрыша с Вами свяжутся организаторы рекламной игры. Также результаты розыгрыша будут опубликованы на сайте evezunchiki.by и отобразятся в Вашем личном кабинете.",
	},
	{
		title: "Зачем предоставлять паспортные данные для получения приза?",
		desc: `В соответствии с Правилами рекламной игры предоставляемая информация для получения приза должна соответствовать Персональным данным, которые Участник указал в регистрационной форме в личном кабинете. В случае несоответствия данных приз такому лицу не отправляется.
    <br /> 
    Организатор осуществляет идентификацию и верификацию на основе данных документа, удостоверяющего личность, которыми и являются паспорт/ID-карта/вид на жительство.`,
	},
	{
		title: "Где и когда можно получить приз?",
		desc: `Полная информация о получении приза указана в п.12 <a href="/rules.pdf" target="_blank">правил рекламной игры</a>`,
	},
	{
		title: "Не нашли ответ на свой вопрос?",
		desc: `Задайте его по телефону <a href="tel:+375297710991" target="_blank">+375 29 771 09 91</a> (МТС, A1, life:)). Режим работы: 8:30 - 20:30, понедельник-воскресенье.`,
	},
];

const Prize = ({ prize }: {
	prize: { title?: string, amount: string, img?: string, description?: string, isTitleLimited?: boolean }
}) => (
	<div className={cls.prizes__card}>
		{prize.img && <img src={prize.img} alt="" />}
		<div className={cls.prizes__amount}>{prize.amount}</div>
		<div
			className={classNames(cls.prizes__title, {
				[cls.limited]: prize.isTitleLimited
			})}
		>
			{hyphenateSync(prize.title || '', {minWordLength: 7})}
		</div>
		{prize.description && <div className={cls.prizes__description}>{prize.description}</div>}
	</div>
);

const Prizes: FC = () => {
	// const prizes = [
	// 	{
	// 		title: "50 000 р.",
	// 		amount: "1 шт",
	// 		img: "assets/images/money-50000.png",
	// 		description: "Главный приз",
	// 	},
	// 	{
	// 		title: "Тур на Шри-Ланку",
	// 		amount: "8 шт",
	// 		img: "assets/images/tour.png",
	// 		description: "Отель 5★ all inclusive",
	// 	},
	// 	{
	// 		title: "1 000 р.",
	// 		amount: "40 шт",
	// 		img: "assets/images/money-1000.png",
	// 		description: "Денежный приз",
	// 	},
	// 	{
	// 		title: "500 р.",
	// 		amount: "80 шт",
	// 		img: "assets/images/money-500.png",
	// 		description: "Денежный приз",
	// 	},
	// ];

	return (
		<div className={cls.prizes}>
			<img src="assets/images/main_prize.png" className={cls.prizes_main} alt="Главный приз" />
			<div className={cls.prizes_money}>
				<div className={cls.prizes_money_left}>
					<img src="assets/images/10000.png" className={cls.prizes_img} alt="Приз 10 000 рублей" />
					<div className={classNames(cls.prizes_text, cls.prizes_text1)}>2 денежных приза по 10 000 р.</div>
				</div>
				<div className={cls.prizes_money_right}>
					<div>
						<img src="assets/images/1000.png" className={cls.prizes_img} alt="Приз 1 000 рублей" />
						<div className={classNames(cls.prizes_text, cls.prizes_text2)}>40 денежных призов по 1 000 р.</div>
					</div>
					<div>
						<img src="assets/images/500.png" className={cls.prizes_img} alt="Приз 500 рублей" />
						<div className={classNames(cls.prizes_text, cls.prizes_text3)}>120 денежных призов по 500 р.</div>
					</div>
				</div>
			</div>
			{/*{prizes.map((el, index) => (*/}
			{/*	<>*/}
			{/*		<Prize prize={el} key={el.title} />*/}
			{/*		{index !== prizes.length - 1 && <div className={cls.divider} />}*/}
			{/*	</>*/}
			{/*))}*/}
		</div>
	);
};

const Brands = () => {
	const arrEm = [
		{
			url: 'https://emall.by/actions/lucky-goods?brands=38951',
			icon: 'assets/images/brands/1.png?v=9'
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=1532',
			icon: 'assets/images/brands/2.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=850',
			icon: 'assets/images/brands/3.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=38904',
			icon: 'assets/images/brands/4.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=28284',
			icon: 'assets/images/brands/5.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=36073',
			icon: 'assets/images/brands/6.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=10455',
			icon: 'assets/images/brands/7.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=2748',
			icon: 'assets/images/brands/8.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=25608',
			icon: 'assets/images/brands/9.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=38133',
			icon: 'assets/images/brands/10.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=530',
			icon: 'assets/images/brands/11.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=372',
			icon: 'assets/images/brands/12.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=39539',
			icon: 'assets/images/brands/13.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=775',
			icon: 'assets/images/brands/14.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=35679',
			icon: 'assets/images/brands/15.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=653',
			icon: 'assets/images/brands/16.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=39200',
			icon: 'assets/images/brands/17.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=723',
			icon: 'assets/images/brands/19.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=10198',
			icon: 'assets/images/brands/20.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=39139',
			icon: 'assets/images/brands/21.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=338',
			icon: 'assets/images/brands/22.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=24302',
			icon: 'assets/images/brands/23.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=43526',
			icon: 'assets/images/brands/24.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=609',
			icon: 'assets/images/brands/25.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=42577',
			icon: 'assets/images/brands/26.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=32913',
			icon: 'assets/images/brands/27.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=41353',
			icon: 'assets/images/brands/28.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=867',
			icon: 'assets/images/brands/29.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=740',
			icon: 'assets/images/brands/31.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=38878',
			icon: 'assets/images/brands/32.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=798',
			icon: 'assets/images/brands/34.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=42581',
			icon: 'assets/images/brands/35.png?v=9',
		},
		{
			url: 'https://emall.by/actions/lucky-goods?brands=42727',
			icon: 'assets/images/brands/38.png?v=9',
		},
	];

	const arrEd = [
		{
			url: "https://edostavka.by/actions/lucky-goods?filter=%7B%22TrademarkId%22%3A%5B38951%5D%7D",
			icon: "assets/images/brands/1.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?filter=%7B%22TrademarkId%22%3A%5B1532%5D%7D",
			icon: "assets/images/brands/2.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?filter=%7B%22TrademarkId%22%3A%5B850%5D%7D",
			icon: "assets/images/brands/3.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?filter=%7B%22TrademarkId%22%3A%5B38904%5D%7D",
			icon: "assets/images/brands/4.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?filter=%7B%22TrademarkId%22%3A%5B28284%5D%7D",
			icon: "assets/images/brands/5.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?filter=%7B%22TrademarkId%22%3A%5B36073%5D%7D",
			icon: "assets/images/brands/6.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?filter=%7B%22TrademarkId%22%3A%5B2748%5D%7D",
			icon: "assets/images/brands/8.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?filter=%7B%22TrademarkId%22%3A%5B25608%5D%7D",
			icon: "assets/images/brands/9.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B38133%5D%7D",
			icon: "assets/images/brands/10.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B530%5D%7D",
			icon: "assets/images/brands/11.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B372%5D%7D",
			icon: "assets/images/brands/12.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B39539%5D%7D",
			icon: "assets/images/brands/13.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B775%5D%7D",
			icon: "assets/images/brands/14.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B35679%5D%7D",
			icon: "assets/images/brands/15.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B653%5D%7D",
			icon: 'assets/images/brands/16.png?v=91',
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B39200%5D%7D",
			icon: "assets/images/brands/17.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B24351%5D%7D",
			icon: "assets/images/brands/18.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B723%5D%7D",
			icon: "assets/images/brands/19.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B10198%5D%7D",
			icon: "assets/images/brands/20.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B39139%5D%7D",
			icon: "assets/images/brands/21.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B338%5D%7D",
			icon: "assets/images/brands/22.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B24302%5D%7D",
			icon: "assets/images/brands/23.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B43526%5D%7D",
			icon: "assets/images/brands/24.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B609%5D%7D",
			icon: "assets/images/brands/25.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B42577%5D%7D",
			icon: "assets/images/brands/26.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B32913%5D%7D",
			icon: "assets/images/brands/27.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B41353%5D%7D",
			icon: "assets/images/brands/28.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B867%5D%7D",
			icon: "assets/images/brands/29.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B24337%5D%7D",
			icon: "assets/images/brands/30.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B740%5D%7D",
			icon: "assets/images/brands/31.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B38878%5D%7D",
			icon: "assets/images/brands/32.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B836%5D%7D",
			icon: "assets/images/brands/33.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B798%5D%7D",
			icon: "assets/images/brands/34.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B42581%5D%7D",
			icon: "assets/images/brands/35.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B599%5D%7D",
			icon: "assets/images/brands/36.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B42913%5D%7D",
			icon: "assets/images/brands/37.png?v=9",
		},
		{
			url: "https://edostavka.by/actions/lucky-goods?lc=3&filter=%7B%22TrademarkId%22%3A%5B42727%5D%7D",
			icon: "assets/images/brands/38.png?v=9",
		},
	];

	const [activeTab, setActiveTab] = useState<'emall' | 'edostavka'>('emall')
	const arr = activeTab === 'emall' ? arrEm : arrEd;
	return (
		<div className={cls.brands}>
			<div className={cls.tabs}>
				<span
					onClick={() => setActiveTab('emall')}
					style={activeTab === 'emall' ? {color: '#F4303B', borderColor: '#F4303B'} : undefined}
					className={clsx(cls.tabs__tab, cls.tabs__emall, {[cls.tabs__tab_active]: activeTab === 'emall',})}
				>
					emall
				</span>
				<span
					onClick={() => setActiveTab("edostavka")}
					style={activeTab === "edostavka" ? {color: '#02B875', borderColor: '#02B875'} : undefined}
					className={clsx(cls.tabs__tab, cls.tabs__edostavka, {[cls.tabs__tab_active]: activeTab === 'edostavka',})}
				>
					edostavka
				</span>
			</div>
			<div
				className={classNames(cls.brands__block, {
					[cls.blocks_emall]: activeTab === 'emall',
					[cls.blocks_edostavka]: activeTab === 'edostavka',
				})}
			>
				{arr.map((el) => (
					<a key={el.icon} target="_blank" href={el.url} rel="noreferrer">
						<div className={cls.brands__card}>
							<img src={el.icon} alt="" />
						</div>
					</a>
				))}
			</div>
			<a
				className={cls.brands__link}
				target="_blank"
				href={activeTab === 'emall' ? "https://emall.by/actions/lucky-goods" : "https://edostavka.by/actions/lucky-goods "}
				rel="noreferrer"
			>
				<span>Смотреть все товары</span>
			</a>
		</div>
	);
};

const Faq: FC<{
	expendValue: boolean;
	setExpendValue: (value: boolean) => void;
}> = ({ expendValue, setExpendValue }) => {
	const [state, setState] = useState(false);
	const [expend, setExpend] = useState<{ [x: string]: boolean }>({});

	function scrollTo() {
		const element = document.getElementById("draws");
		if (element) {
			element.scrollIntoView({behavior: "smooth", block: "nearest"});
		}
	}

	useEffect(() => {
		const element = document.getElementById("scroll");

		if (element) {
			element.addEventListener("click", scrollTo);
		}
		return () => {
			if (element) {
				element.removeEventListener("click", scrollTo);
			}
		};
	}, [state]);
	useEffect(() => {
		if (expendValue && !expend[2]) {
			setExpend((prev) => {
				const copy = JSON.parse(JSON.stringify(prev));
				copy[2] = true;
				return copy;
			});
			setExpendValue(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expendValue, expend]);
	return (
		<div className={cls.faq}>
			{faq.map((el, idx) => (
				<div
					key={el.title}
					id={`expander-${idx}`}
					onClick={() => setState((prev) => !prev)}
				>
					<Expander
						defaultExpand={false}
						value={!!expend[idx]}
						onChange={(e) =>
							setExpend((prev) => {
								const copy = JSON.parse(JSON.stringify(prev));
								copy[idx] = e;
								return copy;
							})
						}
						title={el.title}
						mix={clsx(cls.expander, idx === faq.length - 1 && cls.expander_margin)}
					>
						<div
							className={cls.expander__desc}
							dangerouslySetInnerHTML={{__html: el.desc}}
						/>
					</Expander>
				</div>
			))}
		</div>
	);
};

const Rules: FC = () => {
	const arr = [
		{
			title: "Подтверждайте участие",
			desc: (
				<>
					Подтвердите участие в рекламной игре в личном кабинете, проставив{" "}
					согласие
					<br />
					(<a
					href="https://edostavka.by/profile?ServiceId=32"
					target="_blank"
					rel="noreferrer"
				>
					edostavka
				</a>,{" "}<a
					href="https://emall.by/profile?ServiceId=32"
					target="_blank"
					rel="noreferrer"
				>
					emall
				</a>)
				</>
			),
		},
		{
			title: "Покупайте товары-везунчики",
			desc: (
				<>
					Получайте игровые коды за каждые 2 р. стоимости товаров-везунчиков. {" "}
					<span onClick={scrollTo}>Подробнее</span>
				</>
			),
		},
		{
			title: "Участвуйте в розыгрышах",
			desc: "20 денежных призов каждую неделю! 2 суперприза по 10 000 рублей и главный приз – 50 000 рублей!",
		},
	];

	function scrollTo() {
		const element = document.getElementById("brands");
		if (element) {
			element.scrollIntoView({behavior: "smooth", block: "start"});
		}
	}

	return (
		<div className={cls.rules}>
			<div className={cls.rules__block}>
				{arr.map((el, idx) => (
					<div className={cls.rules__card} key={el.title}>
						<div className={cls.rules__card__badge} data-number={idx + 1} />
						<span className={cls.rules__card__title}>{el.title}</span>
						<span className={cls.rules__card__desc}>{el.desc}</span>
					</div>
				))}
			</div>
			<a
				className={cls.rules__link}
				target="_blank"
				href="/rules.pdf"
			>
				<Icon size={20} name="download" />
				<span>Скачать правила</span>
			</a>
		</div>
	);
};

interface IWinners {
	PrizeType: 1 | 6 | 8;
	PrizeId: number;
	Winner: string;
	GamePrizeDescription: string;
}

export interface IList {
	DrawDate: string;
	Winners: IWinners[];
}

const Body = () => {
	const [expend, setExpend] = useState(false);
	const [list, setList] = useState<IList[]>([]);
	const [listEd, setListEd] = useState<IList[]>([]);
	useEffect(() => {
		request
			.post<IList[]>("Game.EcommerceGameWinnersGet", {
				GameGroupId: "52",
				RoundNumber: "1",
			})
			.then((e) => {
				setList(e ?? []);
			})
			.catch((e) => console.log("err", e));

		// request
		//   .post<IList[]>("Game.EcommerceGameWinnersGet", {
		//     GameGroupId: "47",
		//     RoundNumber: "1",
		//   })
		//   .then((e) => {
		//     setListEd(e ?? []);
		//   })
		//   .catch((e) => console.log("err", e));
	}, []);

	return (
		<div id="body" className={clsx(cls.body)}>
			<Block name="Правила игры" mix={cls.rules__wrapper}>
				<Rules />
			</Block>
			<Block name="Призы" mix={classNames(cls.noneOverflow, cls.prizes_block)}>
				<Prizes />
			</Block>
			<Block id="draws" name="Розыгрыши и победители">
				<DrawsAndWinners listEd={listEd} list={list} />
			</Block>
			<Block id="brands" name="Бренды">
				<Brands />
			</Block>
			<Block mix={classNames(cls.expanderWrap, cls.small)} name="Вопросы">
				<Faq expendValue={expend} setExpendValue={setExpend} />
			</Block>
		</div>
	);
};

export default Body;
