import classNames from "classnames";
import moment from "moment";
import React, { FC, Fragment, useEffect, useRef, useState} from "react";
import { asyncModal } from "../../../helpers/asyncModal";
import { IList } from "../../Body";
import { Modal } from "../../modalComponents";
import { Adaptive } from "../../adaptive";
import { Arrow } from "../../arrow";

import styles from "./index.module.scss";
import { Button } from "ui-kit-euroopt";

interface IFieldModal {
  close: () => void;
  title: string;
  list: IList[];
  idx: number;
}

interface IOpenList {
  [x: string]: boolean
}

interface ICard {
  name: string;
  info: string;
  type?: 'emall' | 'edostavka';
}

const Card: FC<ICard> = ({ name, info }) => {
  return (
    <div className={styles.card}>
      <span className={styles.card__name}>{name}</span>
      <span className={styles.card__info}>{info}</span>
    </div>
  );
};

const FieldModal: FC<IFieldModal> = ({ close, title, list, idx }) => {
  const [activeIdx, setActiveIdx] = useState<number>(idx);
  const [openList, setOpenList] = useState<IOpenList>({});
  const [arrowsIsVisible, setArrowsIsVisible] = useState({ left: false, right: false });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToId(activeIdx);
  }, []);


  useEffect(() => {
    checkArrowIsVisible();
  }, []);

  const scrollToId = (id: number) => {
    const element = document.getElementById(`filter_${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", inline: "center" });
      checkArrowIsVisible();
    }
  };

  const checkArrowIsVisible = (): void => {
    const element = containerRef.current;

    if (element?.scrollWidth) {
      setArrowsIsVisible({
        left: element.scrollLeft > 10,
        right: element.scrollWidth - element.offsetWidth - 10 > element.scrollLeft,
      });
    }
  };

  const scrollTo = (direction: 'left' | 'right'): void => {
    const element = containerRef.current;

    if (element) {
      const { scrollLeft, children } = element;
      const { width } = children[0].getBoundingClientRect();
      const widthWithPadding = (width + 16) * 5;
      const position = direction === 'right' ? scrollLeft + widthWithPadding : scrollLeft - widthWithPadding;
      element.scroll({ left: position, behavior: 'smooth' });
      setTimeout(checkArrowIsVisible, 300);
    }
  };

  const renderWinner = (id: number, name: string, isPrizeType?: boolean) => {
    const curList = list[activeIdx - 1].Winners;
    const filterProp = isPrizeType ? "PrizeType" : "PrizeId";
    const filterCurList = curList.filter((el) => el[filterProp] === id);
    const index = `${id}_${activeIdx}`;

    return (
        <>
          {filterCurList
              .filter((el, idx) =>
                  filterCurList.length > 4
                      ? !(idx > 4 && !openList[index])
                      : true
              )
              .map((el, idx) => (
                  <>
                    {idx === 0 && <span className={styles.titleText}>{name}</span>}
                    <Card name={el.Winner} info={el.GamePrizeDescription} />
                  </>
              ))}
          {!openList[index] && filterCurList.length > 5 ? (
              <Button
                  mix={styles.button}
                  onClick={() => {
                    const copy = JSON.parse(JSON.stringify(openList));
                    copy[index] = true;
                    setOpenList(copy);
                  }}
              >
                Смотреть все
              </Button>
          ) : null}
        </>
    );
  };

  return (
      <Fragment>
        <Modal.Swiper onSwipeBottom={close} />
        <Modal.Close onClose={close} />
        <h3 className={styles.title}>Розыгрыши и победители</h3>

        <div className={styles.field}>
          <div className={styles.container_wrapper}>
            <div
                className={classNames(styles.container, {
                  [styles.container_left]: arrowsIsVisible.left,
                  [styles.container_right]: arrowsIsVisible.right,
                })}
            >
              <Adaptive.Desktop>
                {arrowsIsVisible.left && <Arrow onClick={() => scrollTo('left')} direction="left" size="small" mix={classNames(styles.arrow)} />}
                {arrowsIsVisible.right && (
                    <Arrow
                        onClick={() => scrollTo('right')}
                        direction="right"
                        size="small"
                        mix={classNames(styles.arrow, styles.arrow_right)}
                    />
                )}
              </Adaptive.Desktop>
              {list.length > 0 && (
                  <div className={styles.items} ref={containerRef}>
                    {list.map((el, idx) => (
                        <div
                            key={idx}
                            id={`filter_${idx + 1}`}
                            onClick={() =>  {
                              setActiveIdx(idx + 1);
                              scrollToId(idx + 1)
                            }}
                            className={classNames(
                                styles.tab,
                                activeIdx === idx + 1 && styles.tab__active
                            )}
                        >
                <span>
                  {idx === 4 ? 'Суперприз 1' : idx === list.length - 2 ? 'Суперприз 2' : idx === list.length - 1 ? 'Главный розыгрыш' : moment(el.DrawDate).format("DD.MM")}
                </span>
                        </div>
                    ))}
                  </div>
              )}
            </div>
          </div>
          <div className={styles.wrapper}>
            {list[activeIdx - 1].Winners.length ? (
                <>
                  {renderWinner(710, "Квартира в Минске")}
                  {renderWinner(709, "Тур на Шри-Ланку")}
                  {renderWinner(711, "Денежный приз 10 000 BYN")}
                  {renderWinner(693, "Денежный приз 5 000 BYN")}
                  {renderWinner(708, "Денежный приз 1 000 BYN")}
                  {renderWinner(707, "Денежный приз 500 BYN")}
                  {renderWinner(694, "Денежный приз 50 000 BYN")}
                  {renderWinner(706, "Автомобиль Belgee X50")}
                  {renderWinner(1, "Сертификаты", true)}
                  {renderWinner(700, "IPhone 15")}
                  {renderWinner(699, "IPhone 15")}
                  {renderWinner(698, "IPhone 15")}
                  {renderWinner(697, "Пылесосы MAUNFELD")}
                  {renderWinner(702, "Электросамокаты Artway")}
                  {renderWinner(703, "Роботы-пылесосы Xiaomi")}
                  {renderWinner(704, "Роботы-мойщики окон Даджет")}
                  {renderWinner(705, "Умные колонки Яндекс")}
                  {renderWinner(691, "Телевизоры ARTEL")}
                </>
            ) : (
                <span className={styles.wrapper__null}>
                Ожидается розыгрыш
                <br />
                <br />
                    {activeIdx === 5 || activeIdx === list.length - 1 ? (
                        <>
                            1 денежный приз 10 000 р.
                        </>
                    ) : activeIdx === list.length ? (
                            <>
                                1 денежный приз 50 000 р.
                            </>
                        )
                        : (
                            <>
                                15 денежных призов по 500 р.
                                <br/>
                                5 денежных призов по 1 000 р.
                            </>
                        )}
              </span>
            )}
          </div>
        </div>
      </Fragment>
  );
};

export const openFieldModal = (list: IList[], idx: number, listEd?: IList[]): Promise<void> =>
  asyncModal(
    FieldModal,
    { list, idx, listEd },
    {
      closeOnRouteChange: false,
      classNames: {
        modal: classNames("swiping", styles.modal_field),
      },
      showCloseIcon: false,
      focusTrapped: false,
    }
  );
