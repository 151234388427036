import { Icon } from "ui-kit-euroopt";
import cls from "../Footer/index.module.scss";
import { Adaptive } from "../adaptive";

const Footer = () => {
  return (
    <footer id="footer" className={cls.footer}>
      <div className={cls.footer__wrapper}>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-by-cars.pdf" target="_blank">
            Результаты розыгрышей по рекламной игре «Е-везунчики. По машинам!» (16.10–10.12.2024) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-at-home4.pdf" target="_blank">
            Результаты розыгрышей по рекламной игре «Е-везунчики. На домашнем!» (14.08–08.10.2024) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-on-chill5.pdf" target="_blank">
            Результаты розыгрышей по рекламной игре «Е-везунчики. На чиле!» (12.06–06.08.2024) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-on-drive2.pdf" target="_blank">
            Результаты розыгрышей по рекламной игре «Е-везунчики. На драйве!» (10.04–04.06.2024) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-love.pdf" target="_blank">
              Результаты розыгрышей по рекламной игре «Е-везунчики. Это любовь!» (17.01–12.03.2024) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__results}>
          <a href="/assets/files/winners-doubling.pdf" target="_blank">
            Результаты розыгрышей по рекламной игре «Е-везунчики. Удваиваемся!» (28.09 – 22.11.2023) <Icon name="chevron-12" />
          </a>
        </div>
        <div className={cls.footer__copyright} dangerouslySetInnerHTML={{ __html: `Рекламная игра «Е-везунчики. Курс на деньги!» Срок проведения с 15.01.2025 по 14.05.2025 (включая период выдачи призов и публикации результатов). Свидетельство о регистрации №4503 от 26.12.2024 года, выданное Министерством антимонопольного регулирования и торговли Республики Беларусь. ООО «ЭфСиБи Бел», УНП 193185741.` }} />
      </div>
    </footer>
  );
};
export default Footer;
