import clsx from "clsx";
import { Tooltip } from "ui-kit-euroopt";
import { Adaptive } from "../adaptive";
import cls from "../Header/index.module.scss";

const Header = () => {
  return (
    <header id="header" className={clsx(cls.header)}>
      <div className={cls.header__content}>
        <div className={cls.header__content__logoWrap}>
          <a href="https://emall.by" target="_blank"
            rel="noreferrer">
            <img className={cls.header__content__logo} src="assets/images/emall-logo3.svg" alt="logo" />
          </a>
          <div className={cls.header__content__line} />
          <a href="https://edostavka.by" target="_blank"
            rel="noreferrer">
            <img className={cls.header__content__logo2} src="assets/images/ed-logo3.svg" alt="logo" />
          </a>
        </div>
      </div>
      <div className={cls.header__content2}>
        <Tooltip
            id="1"
            classes={{
              action: cls.tooltip__action,
              box: cls.tooltip,
            }}
            message={
              <div className={cls.header__content__tooltip}>
                <a href="tel:+375297710991" target="_blank" rel="noreferrer">
                  +375 29 771 09 91
                </a>
                <span>MTC, A1, Life:)</span>
                <span>8:30-20:30, Пн-Вс</span>
              </div>
            }
        >
          <div className={cls.header__content__phone}>
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M5.63159 7.66497C6.13582 8.57903 6.71696 9.45628 7.47148 10.2562C8.22843 11.0611 9.16974 11.7936 10.3882 12.4193C10.4785 12.4635 10.564 12.4635 10.6409 12.4328C10.7569 12.3886 10.8753 12.2917 10.9913 12.1752C11.0817 12.0844 11.194 11.9396 11.3112 11.7813C11.78 11.1617 12.3599 10.3924 13.1792 10.7777C13.1975 10.7863 13.2109 10.7961 13.2292 10.8034L15.9616 12.3825C15.9701 12.3874 15.9799 12.396 15.9872 12.4009C16.3474 12.65 16.4963 13.034 16.5 13.4696C16.5 13.9125 16.3376 14.4106 16.0995 14.8315C15.7846 15.3873 15.3206 15.7553 14.7859 15.9983C14.2767 16.2338 13.7103 16.3602 13.1657 16.4412C12.3111 16.5676 11.5102 16.4866 10.691 16.2338C9.89007 15.9848 9.08306 15.5737 8.20157 15.0265L8.13687 14.9848C7.73275 14.7308 7.29567 14.4597 6.86713 14.1395C5.2995 12.9493 3.70135 11.2304 2.65993 9.3385C1.78699 7.75085 1.31084 6.03683 1.57089 4.40379C1.71495 3.50813 2.09709 2.69345 2.7637 2.15606C3.34485 1.68492 4.12744 1.42726 5.14079 1.51805C5.25677 1.52664 5.36055 1.59412 5.41549 1.69473L7.16747 4.67126C7.42386 5.00498 7.45561 5.33626 7.3152 5.66753C7.19922 5.93868 6.96481 6.18897 6.64493 6.42209C6.55092 6.50306 6.4386 6.58527 6.32017 6.67115C5.92826 6.95703 5.48264 7.28707 5.63525 7.67478L5.63159 7.66497Z"
                  stroke="#777778"
                  strokeWidth="1.6"
              />
            </svg>{" "}
            <span>Помощь</span>
          </div>
        </Tooltip>
        <img
          className={cls.header__content2__img1}
          src="/assets/images/bigLogo9.png"
          alt="Big logo"
        />
        <span className={cls.header__content2__btn}>С 15 января по 11 марта</span>
        <div className={cls.header__content2__mock} />
        <Adaptive.Desktop width={576}>
          <img src="/assets/images/main_img.png" className={cls.header__content2__img2} alt="" />
        </Adaptive.Desktop>
        <Adaptive.Mobile width={576}>
          <img src="/assets/images/main_img_mobile.png" className={cls.header__content2__img2} alt="" />
        </Adaptive.Mobile>
      </div>
    </header>
  );
};
export default Header;
